@import "modules/responsive-type.scss";

$primary: #666;/* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop


@import url('https://fonts.googleapis.com/css?family=Alex+Brush|Alfa+Slab+One|Russo+One');
// font-family: 'Russo One', sans-serif;
// font-family: 'Alex Brush', cursive;
// font-family: 'Alfa Slab One', cursive;



html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
    // font-family: 'Alex Brush', cursive;
    font-family: 'Russo One', sans-serif;
    font-size: 1rem;
}

h1 {
   font-family: 'Alfa Slab One', cursive;
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 { 
    font-family: 'Russo One', sans-serif;
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 { 
    font-family: 'Russo One', sans-serif;
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.2);

    .navbar-nav {
        >li>a {
            font-family: 'Alfa Slab One', cursive;
            text-align: center;
            margin-top: 24px;
            display: flex;
            align-items: center;
            color: $blk;
            transition-duration: 0.5s;
            border-radius: $border-radius;

                @media (max-width: 767px) {
                    margin-top: 0;
                    padding: 6px 0px;
                    display: inline-block;
                }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 15px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 40px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 300px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 200px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 150px;
    }
    .navbar-toggle {
        margin-top: 10px;
    }
}

.top-pad {
    padding: 3em 0em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em 0em;
    }    
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {
        font-size: 1rem;
    
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}

#signUpForm {
    padding: 0px 10px !important;
}


#circle {
    width: 300px;
    height: 300px;
    // background: red;
    -moz-border-radius: 150px;
    -webkit-border-radius: 150px;
    border-radius: 150px;

    // @media (max-width: 767px) {

    //      width: 150px;
    //     height: 150px;
    // // background: red;
    //     -moz-border-radius: 150px;
    //     -webkit-border-radius: 150px;
    //     border-radius: 75px;
    // }


    @media (max-width: 767px) {

         width: 225px;
        height: 225px;
    // background: red;
        -moz-border-radius: 125px;
        -webkit-border-radius: 125px;
        border-radius: 125px;
    }
}


.roundthis{
    border-radius: 25px;
}

.padTop{
    padding-top: 25px;
}

.padBot{
    padding-bottom: 25px;
}

.intro{
    background: url(../img/banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    padding: 400px 0px;

    @media (max-width: 767px){
        padding: 200px 0px;
    }

    @media (max-width: 320px){
        padding: 150px 0px;
    }

    h1{
        color: #fff;
    }
}

.divider{
    // background: url(../img/divider-bg.jpg);
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/divider-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    padding: 50px 0px;

    @media (max-width: 767px){
        padding: 50px 0px;
    }

    @media (max-width: 320px){
        padding: 50px 0px;
    }

    .img1 {
     background: url(../img/img1.jpg);
    // background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 60%, #d10000 100%), url(../img/img1.jpg) no-repeat;
    // background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 60%, #d10000 100%), url(../img/img1.jpg) no-repeat;
    height: 300px;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
    // padding: 300px 20px;
    /* background-position: 11% 20%; */
    color: #fff;
    background-color: #f8f8f8;
    border: 5px solid #fff;
    }
    
    .img2 {
     background: url(../img/img2.jpg);
    // background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 60%, #d10000 100%), url(../img/img1.jpg) no-repeat;
    // background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 60%, #d10000 100%), url(../img/img1.jpg) no-repeat;
    height: 300px;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
    // padding: 300px 20px;
    /* background-position: 11% 20%; */
    color: #fff;
    background-color: #f8f8f8;
    border: 5px solid #fff;
    }
    


    
    h1,h2,p{
        color: #fff;
    }
}


.form{
    background: #091113;
    // background: url(../img/form-bg.jpg);
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: 50% 50%;
    // padding: 400px 0px;

    .bg1{
        background: url(../img/form-bg-a.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        padding: 350px 0px;
        border: 5px solid #fff;

    }

    h1{
        color: #fff;
    }
}


.divider2{
    // background: url(../img/divider2-bg.jpg);
    background: #091113;
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: 50% 50%;
    padding: 25px 0px 50px;

        .img3 {
     background: url(../img/img3.jpg);
    // background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 60%, #d10000 100%), url(../img/img1.jpg) no-repeat;
    // background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 60%, #d10000 100%), url(../img/img1.jpg) no-repeat;
    height: 300px;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
    // padding: 300px 20px;
    /* background-position: 11% 20%; */
    color: #fff;
    background-color: #f8f8f8;
    border: 5px solid #fff;
    }

        .img4 {
     background: url(../img/img4.jpg);
    // background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 60%, #d10000 100%), url(../img/img1.jpg) no-repeat;
    // background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 60%, #d10000 100%), url(../img/img1.jpg) no-repeat;
    height: 300px;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
    // padding: 300px 20px;
    /* background-position: 11% 20%; */
    color: #fff;
    background-color: #f8f8f8;
    border: 5px solid #fff;
    }

        .img5 {
     background: url(../img/img5.jpg);
    // background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 60%, #d10000 100%), url(../img/img1.jpg) no-repeat;
    // background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 60%, #d10000 100%), url(../img/img1.jpg) no-repeat;
    height: 300px;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
    // padding: 300px 20px;
    /* background-position: 11% 20%; */
    color: #fff;
    background-color: #f8f8f8;
    border: 5px solid #fff;
    }

        .img6 {
     background: url(../img/img6.jpg);
    // background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 60%, #d10000 100%), url(../img/img1.jpg) no-repeat;
    // background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 60%, #d10000 100%), url(../img/img1.jpg) no-repeat;
    height: 300px;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
    // padding: 300px 20px;
    /* background-position: 11% 20%; */
    color: #fff;
    background-color: #f8f8f8;
    border: 5px solid #fff;
    }

    h1{
        color: #fff;
    }
}

.outro{
    // background: url(../img/banner2.jpg);
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: 50% 50%;
    padding: 0px 0px;
    

    .row{
        margin-top: -140px;
    }

    .overthetop{ 
        position: relative;
        top: 300px;
        color: #fff;
        z-index: 500;

        @media (max-width: 1200px){
            top: 300px;
        }

        @media (max-width: 991px){
            top: 250px;
        }

        @media (max-width: 767px){
            top: 100px;
        }
        
        @media (max-width: 320px){
            top: 50px;
        }
    }

    .bga{
        // background: url(../img/outro-bg-a.jpg);
            background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/outro-bg-a.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 100% 50%;
        padding: 400px 0px;
        
        @media (max-width: 991px){
            padding: 300px 0px;
        }
        
        @media (max-width: 767px){
            padding: 200px 0px;
        }

        @media (max-width: 320px){
            padding: 150px 0px;
            background-position: 60% 50%;
        }
    }

    .bgb{
        // background: url(../img/outro-bg-c.jpg);
            background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/outro-bg-c.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0% 50%;
        padding: 400px 0px;

        @media (max-width: 991px){
            padding: 300px 0px;
        }
        
        @media (max-width: 767px){
            padding: 200px 0px;
        }

        @media (max-width: 320px){
            padding: 150px 0px;
            // background-position: 0% 50%;
        }
    }

    h1{
        color: #fff;
    }
}

.btn {
  font-family: 'Quicksand', sans-serif;
  background-color: $primary;
  // border-color: rgba(255,255,255,1); 
  font-size: 15px;
  padding: 1em 2em;
  color: $wht;

    &:hover {
        background-color: $secondary; 
        //border-color: rgba(255,255,255,1); 
        color: $wht;

        @media (max-width: 1366px) {
            background-color: $primary;
            color: $wht;
        }

    &:active, &:focus {
        background-color: $primary;
        color: $wht !important;
        }
    }
}